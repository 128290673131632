import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { InjectionToken, NgModule } from '@angular/core';
import { AAA_CONFIG, AAAModule, AutoSilentRenewOnErrorService, TokenInterceptor } from '@fret-ngx/aaa';
import {
  SUBSCRIPTION_MANAGEMENT_CONFIGURATION,
  SubscriptionManagerConfiguration,
  SubscriptionManagementModule,
} from '@fret-ngx/subscription-management';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { APP_CONFIG } from '../config/config';
import { aaaConfigFactory } from './config/aaa-config-factory';
import { PermissionsGuard } from './guards/permissions.guard';
import { AuthenticatedRolesService } from './services/authenticated-roles.service';
import { IdentityManagementService } from './services/identity-management.service';
import { PermissionsService } from './services/permissions.service';
import { AuthEffects } from './store/auth.effects';
import * as authReducer from './store/auth.reducer';

export const WindowLocation = new InjectionToken('windowLocation');

@NgModule({
  imports: [
    SubscriptionManagementModule.withConfig({} as SubscriptionManagerConfiguration),
    AAAModule.withConfig({}),
    StoreModule.forFeature(authReducer.featureKey, authReducer.reducer),
    EffectsModule.forFeature([AuthEffects]),
  ],
  providers: [
    TokenInterceptor,
    AutoSilentRenewOnErrorService,
    IdentityManagementService,
    AuthenticatedRolesService,
    PermissionsService,
    PermissionsGuard,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    {
      provide: WindowLocation,
      useValue: location,
    },
    {
      provide: AAA_CONFIG,
      useFactory: aaaConfigFactory,
      deps: [WindowLocation, APP_CONFIG],
    },
  ],
})
export class AuthModule {}
