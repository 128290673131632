import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';

import * as snackBarActions from './snackbar.actions';

@Injectable()
export class SnackbarEffects {
  openSnackBar$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(snackBarActions.displaySnackbar),
        tap(({ config }) => {
          this.snackBar.open(config.announcementMessage, '', { ...config, duration: config.duration ?? 4000 });
        })
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private snackBar: MatSnackBar) {}
}
