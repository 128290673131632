import { NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { RouterEffects, RouterSerializer } from '@fret-ngx/core/ngrx-helpers';
import { EffectsModule } from '@ngrx/effects';
import { NavigationActionTiming, routerReducer, RouterState, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';

import { environment } from '../../../environments/environment';
import { featureKey } from '../routing/store/router.reducer';
import { AppEffects } from './app/app.effects';
import * as appReducer from './app/app.reducer';
import { SnackbarEffects } from './snackbar/snackbar.effects';

@NgModule({
  imports: [
    StoreModule.forRoot(
      { [featureKey]: routerReducer },
      {
        runtimeChecks: environment.storeRuntimeChecks,
      }
    ),
    StoreModule.forFeature(appReducer.featureKey, appReducer.reducer),
    StoreRouterConnectingModule.forRoot({
      stateKey: featureKey,
      routerState: RouterState.Minimal,
      serializer: RouterSerializer,
      navigationActionTiming: NavigationActionTiming.PostActivation,
    }),
    EffectsModule.forRoot([RouterEffects, AppEffects, SnackbarEffects]),
    ...environment.modules,
    MatSnackBarModule,
  ],
})
export class RootStoresModule {}
