import { notificationInfo } from 'allint/notification';
import { AllintViewInfo } from 'allint-core';

import { externalToolsViewInfo } from './external-tools/external-tools-info';

export enum RouteName {
  ExternalTools = 'external-tools',
}

export interface ImintView extends AllintViewInfo {
  route?: RouteName;
  icon?: { iconName: string; isSvgIcon: boolean };
  displayedInMenu: boolean;
}

export const imintViews: Record<AllintViewInfo['id'], ImintView> = {
  [externalToolsViewInfo.id]: {
    ...externalToolsViewInfo,
    route: RouteName.ExternalTools,
    icon: { iconName: 'launch', isSvgIcon: false },
    displayedInMenu: false,
  },
  [notificationInfo.id]: {
    ...notificationInfo,
    displayedInMenu: false,
  },
};
