import { StoreDevtoolsModule } from '@ngrx/store-devtools';

export const environment = {
  production: false,
  storeRuntimeChecks: {
    strictStateImmutability: true,
    strictActionImmutability: true,
    strictStateSerializability: true,
    strictActionSerializability: false,
    strictActionWithinNgZone: true,
    strictActionTypeUniqueness: true,
  },
  modules: [
    StoreDevtoolsModule.instrument({
      name: 'imint-imint-ui',
      maxAge: 100,
    }),
  ],
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error'; // Included with Angular CLI.
