import { Inject, Injectable } from '@angular/core';
import { Translation, TranslocoService } from '@ngneat/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, filter, map, switchMap } from 'rxjs/operators';

import { APP_CONFIG, AppConfig } from '../../config/config';
import * as snackBarActions from '../../stores/snackbar/snackbar.actions';
import { TranslocoHttpLoader } from '../../transloco/transloco-http-loader.service';
import * as appActions from './app.actions';

@Injectable()
export class AppEffects {
  constructor(
    private actions$: Actions,
    private translocoHttpLoader: TranslocoHttpLoader,
    private translationService: TranslocoService,
    @Inject(APP_CONFIG) public config: AppConfig
  ) { }
}
