export enum Roles {
  USER = 'user',
  DATAENG = 'data-eng',
  MANAGER = 'manager',
  ADMIN = 'admin',
}

export type Right = string;

interface Permissions {
  [key: string]: Right;
}

interface GroupedPermissions {
  [key: string]: Permissions;
}

export const GroupedPermissions: GroupedPermissions = {
  JOB: {
    CREATE: 'job/create',
    EDIT: 'job/edit',
    DELETE: 'job/delete',
    VIEW: 'job/view',
  },
};

export const rolesAllowedTo: { [key in Roles]: string[] } = {
  [Roles.ADMIN]: [GroupedPermissions.JOB.VIEW, GroupedPermissions.JOB.DELETE, GroupedPermissions.JOB.EDIT, GroupedPermissions.JOB.CREATE],
  [Roles.USER]: [GroupedPermissions.JOB.VIEW],
  [Roles.DATAENG]: [GroupedPermissions.JOB.VIEW, GroupedPermissions.JOB.CREATE],
  [Roles.MANAGER]: [GroupedPermissions.JOB.VIEW, GroupedPermissions.JOB.CREATE],
};
