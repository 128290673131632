import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotificationService, Page, Notification } from 'allint/notification';
import { Observable } from 'rxjs';

import { ConfigService } from '../../../core/config/config.service';

@Injectable()
export class MintNotificationService implements NotificationService {
  private readonly baseApiURL = this.configService.getConfiguration('notificationApiURL');

  constructor(private configService: ConfigService, private http: HttpClient) {}

  count(): Observable<number> {
    return this.http.get<number>(`${this.baseApiURL}/event/count`);
  }

  countUnread(): Observable<number> {
    return this.http.get<number>(`${this.baseApiURL}/event/unread/count`);
  }

  getPage(shouldGetOnlyUnread: boolean, page: Page): Observable<Notification[]> {
    let params = new HttpParams().set('page', page.pageIndex).set('size', page.pageSize);
    if (shouldGetOnlyUnread) {
      params = params.set('read', false);
    }
    return this.http.get<Notification[]>(`${this.baseApiURL}/events`, { params });
  }

  updateAllToRead(): Observable<void> {
    return this.http.post<void>(`${this.baseApiURL}/event/read/all`, null);
  }

  updateReadStatus(ids: Notification['eventId'][], read: boolean): Observable<void> {
    const endpoint = read ? '/event/read' : '/event/unread';
    return this.http.post<void>(`${this.baseApiURL}${endpoint}`, ids);
  }
}
