import { ConnectedPosition } from '@angular/cdk/overlay';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'imint-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolbarComponent {
  @Input() title: string | null;
  @Input() userEmail: string | null;
  @Input() isActivityPanelEnabled: boolean = false;
  @Input() unreadNotificationCount: number;

  @Output() logout = new EventEmitter<void>();
  @Output() loadNotifications = new EventEmitter<void>();

  readonly notificationOrigin: ConnectedPosition[] = [
    {
      overlayX: 'end',
      overlayY: 'top',
      originX: 'end',
      originY: 'bottom',
    },
  ];

  isOverlayOpen = false;
  isActivityPanelOpen = false;
}
