import { AllintAction, AllintEntry, AllintTransformationTemplate, AllintViewInfo } from 'allint-core';

export const imageTransformationTemplate: AllintTransformationTemplate = {
  canTransform(entry: AllintEntry): boolean {
    return entry.coreType.toLocaleLowerCase() === 'image' && !!entry.coreDataURI;
  },
  id: 'image-transformation',
  label: 'Image transformation',
};

export const hostInfo: AllintViewInfo = {
  actions: [],
  id: 'imint-app',
  name: 'IMINT App',
  transformationTemplates: [imageTransformationTemplate],
};
