import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ImintView } from '../../../view-wrappers/imint-view';

@Component({
  selector: 'imint-view-header',
  templateUrl: './view-header.component.html',
  styleUrls: ['./view-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewHeaderComponent {
  @Input() currentView: ImintView;
  @Input() backRoute: string | null;

  @Output() resetBackRoute = new EventEmitter<void>();
}
