import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Roles, rolesAllowedTo } from '../models/permissions';
import * as authSelectors from '../store/auth.selectors';

@Injectable({ providedIn: 'root' })
export class PermissionsService {
  constructor(private store: Store) {}

  isAllowed(permission: string): Observable<boolean> {
    return this.store
      .select(authSelectors.selectAuthenticatedRoles)
      .pipe(map((roles: Roles[]) => roles.some((role) => role in rolesAllowedTo && rolesAllowedTo[role].includes(permission))));
  }
}
