import { createReducer, on } from '@ngrx/store';
import * as authActions from './auth.actions';
import { AuthState } from './auth.state';

export const featureKey = 'imint-auth';

const initialState: AuthState = {
  identityDetails: null,
  authenticatedRoles: [],
  groups: [],
};

export const reducer = createReducer(
  initialState,
  on(authActions.getUserIdentityDetailsSuccess, (state, { identityDetails }) => ({
    ...state,
    identityDetails,
  })),
  on(authActions.getUserRolesSuccess, (state, { authenticatedRoles }) => ({
    ...state,
    authenticatedRoles,
  })),
  on(authActions.getUserGroupsSuccess, (state, { groups }) => ({
    ...state,
    groups,
  }))
);
