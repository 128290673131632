import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfigService } from '../../config/config.service';
import { Group, User, UserWithoutName } from '../models/identity-management';

@Injectable({ providedIn: 'root' })
export class IdentityManagementService {
  protected route: string;

  constructor(private httpClient: HttpClient, configService: ConfigService) {
    this.route = configService.getDomain('identityManagementConfig');
  }

  getAllUsers(): Observable<User[]> {
    return this.httpClient.get<UserWithoutName[]>(`${this.route}/user`).pipe(
      map((users) =>
        users.map(
          (user) =>
            ({
              ...user,
              name: user.email,
            } as User)
        )
      )
    );
  }

  getAllGroups(): Observable<Group[]> {
    return this.httpClient.get<Group[]>(`${this.route}/group`);
  }
}
