import { Action, createReducer, on } from '@ngrx/store';

import * as appActions from './app.actions';
import { AppState } from './app.state';

export const featureKey = 'imint-app';

const initialAppState: AppState = {
  backRoute: null,
};

const appReducer = createReducer(
  initialAppState,
  on(appActions.setBackRoute, (state, { backRoute }) => ({
    ...state,
    backRoute,
  })),
  on(appActions.resetBackRoute, (state) => ({ ...state, backRoute: null }))
);

export function reducer(state: AppState | undefined, action: Action): AppState {
  return appReducer(state, action);
}
