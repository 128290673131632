import { Inject, Injectable } from '@angular/core';
import { AllintViewConfiguration, AllintViewInfo } from 'allint-core';

import { APP_CONFIG, AppConfig, ConfigWithDomain, ViewConf } from './config';
import { externalToolsViewInfo } from '../../view-wrappers/external-tools/external-tools-info';
import { ExternalTool } from '../../view-wrappers/external-tools/models/external-tools-config';
import { imintViews } from '../../view-wrappers/imint-view';
import { MenuItem } from '../auth/models/menu-item';

type FilterFlags<Base, Condition> = {
  [Key in keyof Base]: Base[Key] extends Condition ? Key : never;
};

type AllowedKey<Base, Condition> = FilterFlags<Base, Condition>[keyof Base];

interface ExternalToolsConfig extends AllintViewConfiguration {
  tools: ExternalTool[];
}

@Injectable({ providedIn: 'root' })
export class ConfigService {
  constructor(@Inject(APP_CONFIG) private app: AppConfig) {}

  getDomain(config: AllowedKey<AppConfig, ConfigWithDomain>): string {
    return this.app[config].domain;
  }

  getOidcRedirectSegment(): string {
    return this.app.oidcRedirectSegment;
  }

  getConfiguration<K extends keyof AppConfig>(key: K): AppConfig[K] {
    return this.app[key];
  }

  getMenuItems(): MenuItem[] {
    const viewsEnabled = Object.keys(this.getConfiguration('views'));
    return Object.values(imintViews).reduce((acc, view) => {
      if (viewsEnabled.includes(view.id)) {
        if (view.displayedInMenu) {
          acc.push({
            name: view.name,
            icon: view.icon,
            route: view.route,
          });
        }
        if (view.id === externalToolsViewInfo.id) {
          const externalTools = (this.getViewConfig('external-tools') as ExternalToolsConfig).tools;
          externalTools.forEach((tool) => {
            acc.push({
              name: tool.label,
              icon: tool.icon,
              route: `${externalToolsViewInfo.id}/${tool.route}`,
            });
          });
        }
      }
      return acc;
    }, [] as MenuItem[]);
  }

  getViewConfig(viewId: AllintViewInfo['id']): ViewConf {
    return this.app.views[viewId];
  }

  getEnabledViewIds(): AllintViewInfo['id'][] {
    return Object.keys(this.app.views);
  }
}
