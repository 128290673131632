import { ChangeDetectionStrategy, Component, createNgModuleRef, HostListener, Injector, OnInit, ProviderToken, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { Title } from '@angular/platform-browser';
import { AuthService } from '@fret-ngx/aaa';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { NotificationFacade } from 'allint/notification';
import {
  ALLINT_INTERACTION_EVENT_TYPE,
  AllintAction,
  AllintInteractionEvent,
  AllintViewFacade,
  AllintViewInfo,
  AllintViewMapsService,
} from 'allint-core';
import { forkJoin, from, Observable, switchMap } from 'rxjs';
import { filter, first, map, tap } from 'rxjs/operators';

import * as authSelectors from './core/auth/store/auth.selectors';
import { ConfigService } from './core/config/config.service';
import * as routerSelectors from './core/routing/store/router.selectors';
import * as appActions from './core/stores/app/app.actions';
import * as appSelectors from './core/stores/app/app.selectors';
import * as snackbarActions from './core/stores/snackbar/snackbar.actions';
import { hostInfo } from './host.info';
import { imintViews, RouteName } from './view-wrappers/imint-view';

class AllintViewWrapperModule {
  static facade: ProviderToken<AllintViewFacade>;
  static info: AllintViewInfo;
}

export const toViewWrapperModuleName = (viewId: AllintViewInfo['id']): string =>
  viewId
    .split('-')
    .map((word: string) => word[0].toUpperCase() + word.slice(1))
    .join('') + 'WrapperModule';

@Component({
  selector: 'imint-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  @ViewChild('clipDrawer', { static: false }) clipDrawer: MatDrawer;

  readonly backRoute$ = this.store.select(appSelectors.selectBackRoute);
  readonly currentUrl$ = this.store.select(routerSelectors.selectCurrentUrl);
  readonly currentView$ = this.store.select(routerSelectors.selectCurrentMintView);
  readonly menuItems = this.configService.getMenuItems();
  readonly userEmail$ = this.store.select(authSelectors.selectUserEmail);
  readonly isActivityPanelEnabled = Object.keys(this.configService.getConfiguration('views')).includes('activity');
  readonly unreadNotificationCount$ = this.notificationFacade.unreadCount$;

  title: string;

  private readonly viewIds = this.configService.getEnabledViewIds();

  constructor(
    private store: Store,
    private authService: AuthService,
    private configService: ConfigService,
    private injector: Injector,
    private viewService: AllintViewMapsService,
    private titleService: Title,
    private notificationFacade: NotificationFacade,
    private transloco: TranslocoService
  ) {
    const title = this.configService.getConfiguration('title');
    this.title = title.header;
    this.titleService.setTitle(title.page);
  }

  @HostListener('dragend', ['$event'])
  onDragEnd(): void {
    this.viewIds.forEach((viewId: AllintViewInfo['id']) => {
      const viewFacade = this.viewService.facadesByViewId.get(viewId);
      if (viewFacade.resetDropzones) {
        viewFacade.resetDropzones();
      }
    });
  }

  @HostListener('dragstart', ['$event'])
  onDragStart(event: DragEvent): void {
    if (event.dataTransfer.types.includes(ALLINT_INTERACTION_EVENT_TYPE)) {
      const allintInteractionEvent: AllintInteractionEvent = JSON.parse(event.dataTransfer.getData(ALLINT_INTERACTION_EVENT_TYPE));

      this.viewIds.forEach((viewId: AllintViewInfo['id']) => {
        const viewInfo = this.viewService.infosByViewId.get(viewId);
        const viewFacade = this.viewService.facadesByViewId.get(viewId);
        if (
          allintInteractionEvent.sourceViewId !== viewInfo.id &&
          viewFacade.highlightDropzones &&
          viewInfo.actions &&
          viewInfo.actions.some((action: AllintAction) => action.canPerform(Object.values(allintInteractionEvent.entriesById)))
        ) {
          viewFacade.highlightDropzones(allintInteractionEvent.entriesById);
        }
      });
    }
  }

  /**
   * Initialize the component and subscribe to identity details changes.
   *
   */
  ngOnInit(): void {
    this.store
      .select(authSelectors.selectIdentityDetails)
      .pipe(
        filter((v) => !!v),
        switchMap(() =>
          forkJoin(
            this.viewIds.map((viewId: string) =>
              from(import(`./view-wrappers/${viewId}/${viewId}-wrapper.module`)).pipe(
                map((mod: Record<string, typeof AllintViewWrapperModule>) => mod[toViewWrapperModuleName(viewId)]),
                tap((ViewWrapperModule: typeof AllintViewWrapperModule) => {
                  createNgModuleRef(ViewWrapperModule, this.injector);
                  this.initStandardView(viewId, ViewWrapperModule);
                })
              )
            )
          )
        )
      )
      .subscribe(() => {
        this.viewService.facadesByViewId.forEach((viewFacade: AllintViewFacade, viewId: AllintViewInfo['id']) => {
          viewFacade.updateConfig({
            ...this.configService.getViewConfig(viewId),
            views: [...this.viewService.infosByViewId.values(), hostInfo],
          });
        });
      });
  }

  logout(): void {
    this.authService.startSignOut();
  }


  resetBackRoute(): void {
    this.store.dispatch(appActions.resetBackRoute());
  }

  loadNotifications(): void {
    this.notificationFacade.loadNotifications();
  }

  private initStandardView(allintViewId: AllintViewInfo['id'], ViewWrapperModule: typeof AllintViewWrapperModule): void {
    // retrieve view's facade & info
    const viewFacade = this.injector.get<AllintViewFacade>(ViewWrapperModule.facade);
    const viewInfo: AllintViewInfo = ViewWrapperModule.info;
    // add facade to view/facade map
    this.viewService.facadesByViewId.set(allintViewId, viewFacade);
    // add info to view/info map
    this.viewService.infosByViewId.set(allintViewId, viewInfo);
    // bind facade interaction$ output to main component's interaction event routing method
    viewFacade.interaction$.subscribe(this.routeInteractionEvent.bind(this));
    if (viewFacade.hasOwnProperty('error$')) {
      // todo: remove cast after allint-core update
      (viewFacade as AllintViewFacade & { error$: Observable<string> }).error$.subscribe((msg) => this.handleViewError(msg));
    }

  }

  private handleViewError(errorMessage: string): void {
    const announcementMessage = errorMessage && errorMessage.indexOf('.') > 0 ? this.transloco.translate(errorMessage) : errorMessage;
    this.store.dispatch(snackbarActions.displaySnackbar({ config: { announcementMessage } }));
  }

  private routeInteractionEvent(event: AllintInteractionEvent): void {
    if (event.targetViewIds && event.targetViewIds.length) {
      // at least one target view
      event.targetViewIds.forEach((viewId: AllintViewInfo['id']) => {
        if (viewId !== hostInfo.id) {
          // view to view(s) interaction
          this.viewService.facadesByViewId.get(viewId).onInteraction(event);

          const targetViewRoute = this.getInteractionNavigationRoute(viewId);
          if (targetViewRoute) {
            this.currentUrl$.pipe(first()).subscribe((backRoute: string) => {
              this.store.dispatch(appActions.setBackRoute({ backRoute }));
            });
          }
        }
      });
    }
  }

  private getInteractionNavigationRoute(viewId: AllintViewInfo['id']): RouteName | undefined {
    return imintViews[viewId]?.route;
  }

}
