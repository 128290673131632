import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Translation, TranslocoLoader } from '@ngneat/transloco';
import { Observable } from 'rxjs';

import { ConfigService } from '../config/config.service';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(private http: HttpClient, private configService: ConfigService) {}

  getTranslation(path: string): Observable<Translation> {
    return this.http.get<Translation>(`${this.configService.getOidcRedirectSegment()}/assets/i18n/${path}.json`);
  }
}
