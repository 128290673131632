import { Params } from '@angular/router';
import { getSelectors, RouterState } from '@fret-ngx/core/ngrx-helpers';
import { RouterReducerState } from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ImintView, imintViews } from '../../../view-wrappers/imint-view';
import * as routerReducer from './router.reducer';

const getRouterState = createFeatureSelector<RouterReducerState<RouterState>>(routerReducer.featureKey);

export const { selectRouteParams, selectQueryParams, selectRouteData, selectUrl } = getSelectors(getRouterState);

export const selectCurrentUrl = createSelector(selectUrl, (url) => url);

export const selectRouteParamCoreDataId = createSelector(selectRouteParams, (params: Params) =>
  params && params['core-data-id'] ? params['core-data-id'] : null
);

export const selectDataRouteTitle = createSelector(selectRouteData, (data: { title?: string }) => (data && data.title ? data.title : null));

export const selectBackRouteParam = createSelector(selectQueryParams, (params: Params) =>
  params && params.backRoute ? params.backRoute : null
);

export const selectCurrentTaskId = createSelector(selectRouteParams, (params: Params) => {
  return params.taskId ? params.taskId : null;
});

export const selectCurrentMintView = createSelector(selectUrl, (url: string | undefined) => {
  if (url) {
    return Object.values(imintViews).find((view: ImintView) => url.startsWith(`/${view.route}`));
  }
  return null;
});
