import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment } from '@angular/router';
import { imintViews } from '../../view-wrappers/imint-view';
import { ConfigService } from '../config/config.service';

@Injectable({ providedIn: 'root' })
export class EnabledViewsGuard implements CanLoad {
  private readonly allowedViewIds = Object.keys(this.configService.getConfiguration('views'));

  constructor(private configService: ConfigService) {}

  canLoad(_route: Route, segments: UrlSegment[]): boolean {
    return this.isAllowed(segments[0].path);
  }

  private isAllowed(path: string): boolean {
    return this.allowedViewIds.some((viewId) => imintViews[viewId]?.route === path);
  }
}
