import { SubscriptionIdentityDetails } from '@fret-ngx/subscription-management';
import { createAction, props } from '@ngrx/store';
import { Group } from '../models/identity-management';
import { Roles } from '../models/permissions';

export const init = createAction('[IMINT Auth] Init');
export const getUserIdentityDetailsSuccess = createAction(
  '[IMINT Auth] Set User Identity Details Success',
  props<{ identityDetails: SubscriptionIdentityDetails }>()
);
export const getUserIdentityDetailsError = createAction('[IMINT Auth] Set User Identity Details Error');
export const getUserRolesSuccess = createAction('[IMINT Auth] Get User Roles Success', props<{ authenticatedRoles: Roles[] }>());
export const getUserRolesError = createAction('[IMINT Auth] Get User Roles Error');
export const getUserGroupsSuccess = createAction('[IMINT Auth] Get User Groups Success', props<{ groups: Group[] }>());
export const getUserGroupsError = createAction('[IMINT Auth] Get User Groups Error');
