import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { Action, createAction } from '@ngrx/store';
import { NotificationFacade } from 'allint/notification';
import { interval } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

const init = createAction('[Notification wrapper] init');

@Injectable()
export class NotificationPullingEffects implements OnInitEffects {
  unreadCountPulling$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(init),
        switchMap(() => interval(10000)),
        tap(() => {
          this.notificationFacade.refreshUnreadCount();
        })
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private notificationFacade: NotificationFacade) {}

  ngrxOnInitEffects(): Action {
    return init();
  }
}
