import { Injectable } from '@angular/core';
import { AuthService } from '@fret-ngx/aaa';
import { latestFrom } from '@fret-ngx/core/rxjs-operators';
import { SubscriptionIdentityDetails, SubscriptionIdentityService } from '@fret-ngx/subscription-management';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { NotificationFacade } from 'allint/notification';
import { catchError, concatMap, filter, first, map, switchMap, tap } from 'rxjs/operators';
import * as snackBarActions from '../../stores/snackbar/snackbar.actions';
import { Roles } from '../models/permissions';
import { AuthenticatedRolesService } from '../services/authenticated-roles.service';
import { IdentityManagementService } from '../services/identity-management.service';
import * as authActions from './auth.actions';
import * as authSelectors from './auth.selectors';

function findRoleEnumKeyByValue(value: string): Roles | null {
  const keys = Object.values(Roles).filter((x) => x === value);
  return keys.length > 0 ? keys[0] : null;
}

const validUserRoles = (roles: Roles[]): Roles[] =>
  roles.reduce((acc, role) => {
    const roleEnumKey = findRoleEnumKeyByValue(role);
    if (!!roleEnumKey) {
      acc.push(roleEnumKey);
    }
    return acc;
  }, [] as Roles[]);

@Injectable({ providedIn: 'root' })
export class AuthEffects implements OnInitEffects {
  getUserIdentityDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.init),
      concatMap(() => this.authService.user),
      filter((user) => user != null && !user.expired),
      concatMap(() => this.identityService.find()),
      map((identityDetails: SubscriptionIdentityDetails) => authActions.getUserIdentityDetailsSuccess({ identityDetails })),
      catchError(() => [authActions.getUserIdentityDetailsError()])
    )
  );

  getUserRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.getUserIdentityDetailsSuccess),
      switchMap(() => this.authenticatedRolesService.get()),
      map((roles) => authActions.getUserRolesSuccess({ authenticatedRoles: validUserRoles(roles) })),
      catchError(() => [authActions.getUserRolesError()])
    )
  );

  getUserGroups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.getUserIdentityDetailsSuccess),
      latestFrom(this.store.select(authSelectors.selectUserId)),
      concatMap((uuid) =>
        this.identityManagementService.getAllGroups().pipe(
          map((groups) => groups.filter((group) => group.userReferences.some((id) => id === uuid))),
          concatMap((groups) => [authActions.getUserGroupsSuccess({ groups })]),
          first(),
          catchError(() => [authActions.getUserGroupsError()])
        )
      )
    )
  );

  handleErrors$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.getUserGroupsError, authActions.getUserRolesError, authActions.getUserIdentityDetailsError),
      map(({ type }) => snackBarActions.displaySnackbar({ config: { announcementMessage: type } }))
    )
  );

  initializeNotifications$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.getUserIdentityDetailsSuccess),
        tap(() => {
          this.notificationFacade.initialize();
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private authService: AuthService,
    private identityService: SubscriptionIdentityService,
    private authenticatedRolesService: AuthenticatedRolesService,
    private identityManagementService: IdentityManagementService,
    private notificationFacade: NotificationFacade,
  ) {}

  ngrxOnInitEffects(): Action {
    return authActions.init();
  }
}
