import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfigService } from '../../config/config.service';
import { Roles } from '../models/permissions';

interface AuthenticatedRolesServiceResponse {
  authenticatedRoles: Roles[];
  serviceName: string;
}

@Injectable({ providedIn: 'root' })
export class AuthenticatedRolesService {
  protected route: string;

  constructor(private httpClient: HttpClient, configService: ConfigService) {
    this.route = configService.getDomain('mintIdpGateway');
  }

  get(): Observable<Roles[]> {
    return this.httpClient
      .get<AuthenticatedRolesServiceResponse>(`${this.route}/authenticatedRoles`)
      .pipe(map((response) => response.authenticatedRoles));
  }
}
