import { APP_BASE_HREF, CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AllintViewMapsService } from 'allint-core';

import { AppComponent } from './app.component';
import { AuthModule } from './core/auth/auth.module';
import { NavbarModule } from './core/components/navbar/navbar.module';
import { ToolbarModule } from './core/components/toolbar/toolbar.module';
import { ViewHeaderModule } from './core/components/view-header/view-header.module';
import { appBaseHrefFactory } from './core/config/app-base-href-factory';
import { APP_CONFIG } from './core/config/config';
import { CustomIconsModule } from './core/custom-icons/custom-icons.module';
import { AppRoutingModule } from './core/routing/app-routing.module';
import { RootStoresModule } from './core/stores/root-stores.module';
import { TranslocoRootModule } from './core/transloco/transloco-root.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    // ANGULAR
    CommonModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    // AUTH
    AuthModule,
    // ROUTING
    AppRoutingModule,
    // NGRX
    RootStoresModule,
    // CMP
    ToolbarModule,
    NavbarModule,
    ViewHeaderModule,
    // MATERIAL
    MatSidenavModule,
    // TRANSLOCO
    TranslocoRootModule,
    // ALLINT
    CustomIconsModule,
  ],
  providers: [
    {
      provide: APP_BASE_HREF,
      useFactory: appBaseHrefFactory,
      deps: [APP_CONFIG],
    },
    AllintViewMapsService,
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: {},
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
