import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { AppConfig, APP_CONFIG } from './app/core/config/config';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

fetch('assets/config.json')
  .then((response: Response) => response.json())
  .then((config: AppConfig) => platformBrowserDynamic([{ provide: APP_CONFIG, useValue: config }]).bootstrapModule(AppModule))
  .catch((err) => {
    console.log(err);
    const alertEl: HTMLDivElement = document.createElement('div');
    alertEl.classList.add('config-error');
    alertEl.innerHTML = `
      <i class="material-icons">error</i>
      <p>An error occured while fetching configuration</p>
    `;
    (document.querySelector('body') as HTMLBodyElement).append(alertEl);
  });
