import { NgModule } from '@angular/core';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ConfigService } from '../config/config.service';

@NgModule({
  imports: [MatIconModule],
})
export class CustomIconsModule {
  constructor(private iconRegistry: MatIconRegistry, private configService: ConfigService, private sanitizer: DomSanitizer) {
    ['dopec', 'i4d', 'kibana', 'nifi', 'owl', 'source_box'].forEach((tool) => {
      this.iconRegistry.addSvgIcon(
        tool,
        this.sanitizer.bypassSecurityTrustResourceUrl(`${this.configService.getOidcRedirectSegment()}/assets/img/tools/${tool}.svg`)
      );
    });
  }
}
