import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Right } from '../models/permissions';
import { PermissionsService } from '../services/permissions.service';

@Injectable({ providedIn: 'root' })
export class PermissionsGuard implements CanActivate {
  constructor(private permissionService: PermissionsService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<UrlTree | boolean> | boolean {
    let result: Observable<UrlTree | boolean> | boolean = true;
    if (route.data) {
      const permissions: Right[] = route?.data?.permissions;
      if (permissions && permissions.length) {
        result = forkJoin(permissions.map((permission) => this.permissionService.isAllowed(permission).pipe(take(1)))).pipe(
          map((allowedOrNot) => (allowedOrNot.every((permission) => permission) ? true : this.router.createUrlTree([''])))
        );
      }
    }
    return result;
  }
}
