<nav>
  <button
    *ngFor="let item of menuItems; trackBy: 'name' | trackByProperty"
    mat-icon-button
    matTooltipPosition="right"
    [matTooltip]="item.name"
    [routerLink]="'/' + item.route"
    routerLinkActive="active"
    (click)="menuItemClicked(item)"
  >
    <ng-container *ngIf="item.icon.isSvgIcon; else isMaterial">
      <mat-icon [svgIcon]="item.icon.iconName"></mat-icon>
    </ng-container>
    <ng-template #isMaterial>
      <mat-icon>{{ item.icon.iconName }}</mat-icon>
    </ng-template>
  </button>
</nav>
