import { InjectionToken } from '@angular/core';
import { AAAConfig } from '@fret-ngx/aaa'
import { AllintViewConfiguration, AllintViewInfo } from 'allint-core';

export interface ConfigWithDomain {
  domain: string;
}

export interface ConfigWithDashboard {
  dashboard: string;
}


export type ViewConf = AllintViewConfiguration | Record<string, unknown>;

export interface AppConfig {
  title: {
    header: string;
    page: string;
  };
  rootSegment: string;
  oidcRedirectSegment: string;
  notificationApiURL: string;
  sharedRepositoryApiURL: string;
  mintIdpGateway: ConfigWithDomain;
  identityManagementConfig: ConfigWithDomain;
  oidcConfig: AAAConfig;
  views: Record<AllintViewInfo['id'], ViewConf>;
}

export const APP_CONFIG = new InjectionToken<AppConfig>('AppConfig');
