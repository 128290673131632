import { AAAConfig } from '@fret-ngx/aaa';
import { AppConfig } from '../../config/config';

export const aaaConfigFactory = (location: Location, appConfig: AppConfig): AAAConfig => {
  const baseUrl = `${location.protocol}//${location.host}${appConfig.oidcRedirectSegment}`;

  return {
    ...appConfig.oidcConfig,
    redirect_uri: `${baseUrl}/auth-callback/signin`,
    silent_redirect_uri: `${baseUrl}/assets/silent-signin-callback.html`,
    post_logout_redirect_uri: `${baseUrl}/auth-callback/signout`,
  };
};
