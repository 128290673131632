import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as authReducer from './auth.reducer';
import { AuthState } from './auth.state';

export const selectState = createFeatureSelector<AuthState>(authReducer.featureKey);
export const selectIdentityDetails = createSelector(selectState, ({ identityDetails }) => identityDetails);
export const selectUserId = createSelector(selectIdentityDetails, (details) => (details?.uuid ? details.uuid : null));
export const selectUserEmail = createSelector(selectIdentityDetails, (details) => (details?.email ? details.email : null));
export const selectAuthenticatedRoles = createSelector(selectState, ({ authenticatedRoles }) => authenticatedRoles);
export const selectGroups = createSelector(selectState, ({ groups }) => groups);
