<mat-toolbar color="accent" cdkOverlayOrigin #toolbar="cdkOverlayOrigin">
  <img src="assets/img/airbus_white.svg" alt="airbus logo" />

  <h1>{{ title }}</h1>

  <button
    *ngIf="isActivityPanelEnabled"
    [ngClass]="{ arrow: isActivityPanelOpen }"
    mat-icon-button
    type="button"
    (click)="isActivityPanelOpen = !isActivityPanelOpen"
  >
    <mat-icon>checklist</mat-icon>
  </button>

  <button
    [ngClass]="{ arrow: isOverlayOpen }"
    mat-icon-button
    type="button"
    (click)="isOverlayOpen = !isOverlayOpen"
    [matBadge]="unreadNotificationCount"
    matBadgeSize="small"
    [matBadgeHidden]="unreadNotificationCount === 0"
    matBadgeColor="warn"
  >
    <mat-icon>notifications</mat-icon>
  </button>

  <button mat-button type="button" class="user-menu-trigger" [matMenuTriggerFor]="menu">
    <mat-icon>account_circle</mat-icon>
    <span *ngIf="userEmail">{{ userEmail }}</span>
  </button>

  <mat-menu #menu="matMenu" xPosition="before">
    <button mat-menu-item type="button" (click)="this.logout.emit()">Logout</button>
  </mat-menu>

  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="toolbar"
    [cdkConnectedOverlayPositions]="notificationOrigin"
    [cdkConnectedOverlayOpen]="isOverlayOpen"
    [cdkConnectedOverlayHasBackdrop]="true"
    cdkConnectedOverlayBackdropClass="notification-panel-backdrop"
    (backdropClick)="isOverlayOpen = !isOverlayOpen"
    (attach)="loadNotifications.emit()"
  >
    <allint-notification-list></allint-notification-list>
  </ng-template>
</mat-toolbar>
