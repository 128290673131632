import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import {
  AllintNotificationListComponent,
  AllintNotificationModule,
  NotificationFacade,
  notificationInfo,
  NotificationService,
} from 'allint/notification';

import { MintNotificationService } from './services/notification.service';
import { NotificationPullingEffects } from './store/notification-pulling.effects';

@NgModule({
  imports: [AllintNotificationModule, EffectsModule.forFeature([NotificationPullingEffects])],
  exports: [AllintNotificationListComponent],
  providers: [{ provide: NotificationService, useClass: MintNotificationService }],
})
export class NotificationWrapperModule {
  static facade = NotificationFacade;
  static info = notificationInfo;
}
