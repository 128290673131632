import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@fret-ngx/aaa';

import { externalToolsViewInfo } from '../../view-wrappers/external-tools/external-tools-info';
import { imintViews, RouteName } from '../../view-wrappers/imint-view';
import { EnabledViewsGuard } from '../guards/enabled-views.guard';

export const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    children: [
      {
        path: imintViews[externalToolsViewInfo.id].route,
        canLoad: [EnabledViewsGuard],
        loadChildren: () =>
          import(`../../view-wrappers/${externalToolsViewInfo.id}/external-tools-wrapper.module`).then((m) => m.ExternalToolsWrapperModule),
      },
      {
        path: '**',
        redirectTo: RouteName.ExternalTools,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
