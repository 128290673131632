import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { MenuItem } from '../../auth/models/menu-item';

@Component({
  selector: 'imint-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarComponent {
  @Input() menuItems: MenuItem[];
  @Input() currentUrl: string;

  @Output() resetBackRoute = new EventEmitter<void>();

  menuItemClicked(item: MenuItem): void {
    this.resetBackRoute.emit();
  }
}
