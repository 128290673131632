<imint-toolbar
  [isActivityPanelEnabled]="isActivityPanelEnabled"
  [title]="title"
  [userEmail]="userEmail$ | async"
  [unreadNotificationCount]="unreadNotificationCount$ | async"
  (logout)="logout()"
  (loadNotifications)="loadNotifications()"
></imint-toolbar>
<mat-drawer-container class="main-container">
  <mat-drawer mode="side" opened class="main-navbar">
    <imint-navbar
      [currentUrl]="currentUrl$ | async"
      [menuItems]="menuItems"
      (resetBackRoute)="resetBackRoute()"
    >
    </imint-navbar>
  </mat-drawer>
  <mat-drawer-content>
    <mat-drawer-container>
      <mat-drawer-content class="view-container">
        <imint-view-header [currentView]="currentView$ | async" [backRoute]="backRoute$ | async" (resetBackRoute)="resetBackRoute()">
        </imint-view-header>
        <div class="page-content">
          <router-outlet></router-outlet>
        </div>
      </mat-drawer-content>
    </mat-drawer-container>
  </mat-drawer-content>
</mat-drawer-container>
